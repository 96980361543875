import "./layout.css";

import PropTypes from "prop-types";
import React from "react";

import Footer from "./footer";
import Header from "./header";
import SkipNav from "./skipNav/skipNav";

const Layout = ({ children }) => {
	return (
		<>
			<SkipNav />
			<Header />
			<main id={"mainContent"}>{children}</main>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
