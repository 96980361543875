import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic-graphql";
import { RichText } from "prismic-reactjs";
import React from "react";

import { linkResolver } from "../../utilities/linkResolver";
import classes from "./footer.module.css";

const footerQuery = graphql`
	query FooterQuery {
		prismic {
			allFooter_navigations {
				edges {
					node {
						body {
							... on PRISMIC_Footer_navigationBodyColumn_section {
								type
								label
								primary {
									content
									column_header
								}
							}
						}
					}
				}
			}
		}
	}
`;

const renderFooter = data => {
	return <Footer data={data} />;
};

const FooterFetch = props => {
	return (
		<StaticQuery
			query={`${footerQuery}`}
			render={withPreview(renderFooter, footerQuery)}
		/>
	);
};

const Footer = props => {
	const doc = props.data.prismic.allFooter_navigations.edges
		.slice(0, 1)
		.pop();

	return (
		<footer className={classes.footerContainer}>
			<div className={classes.footerContent}>
				{doc.node.body.map((item, index: number) => {
					return (
						<div className={classes.column} key={index}>
							<div className={classes.header}>
								{RichText.asText(item.primary.column_header)}
							</div>
							<div className={classes.content}>
								{RichText.render(
									item.primary.content,
									linkResolver
								)}
							</div>
						</div>
					);
				})}
			</div>
		</footer>
	);
};

export default FooterFetch;
