import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";

import classes from "./skipNav.module.css";

class SkipNav extends Component {
	private skipNav;

	componentDidMount() {
		this.skipNav.addEventListener("click", this.handleSkipNav);
	}

	componentWillUnmount() {
		// Make sure to remove the DOM listener when the component is unmounted.
		this.skipNav.removeEventListener("click", this.handleSkipNav);
	}

	render() {
		return (
			<Fragment>
				<div className={classes.skipNavContainer}>
					<button
						aria-label={"Skip Navigation"}
						ref={elem => (this.skipNav = elem)} // eslint-disable-line no-return-assign
					>
						Skip Nav
					</button>
				</div>
			</Fragment>
		);
	}

	handleSkipNav = e => {
		e.preventDefault();
		try {
			const target = document.getElementById("mainContent");
			target.setAttribute("tabindex", "-1");
			target.focus();
		} catch (error) {
			return;
		}
	};
}

export default SkipNav;
