import { StaticQuery, graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic-graphql";
import { RichText } from "prismic-reactjs";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import useMedia from "use-media";

import Drawer from "../../assets/drawer.svg";
import CompanyLogo from "../../assets/logo.svg";
import PrismicHref from "../prismicHref";
import classes from "./header.module.css";

const headerQuery = graphql`
	query HeaderQuery {
		prismic {
			allTop_navigations {
				edges {
					node {
						logo
						_linkType
						links {
							link {
								_linkType
								... on PRISMIC_Page {
									_linkType
									_meta {
										id
										uid
										type
									}
								}
								... on PRISMIC_Home_page {
									_linkType
									_meta {
										id
										uid
										type
									}
								}
								... on PRISMIC_Article_home {
									_linkType
									_meta {
										id
										uid
										type
									}
								}
							}
							link_title
						}
						_meta {
							uid
						}
					}
				}
			}
		}
	}
`;

const renderHeader = data => {
	return <Header data={data} />;
};

const HeaderFetch = props => {
	return (
		<StaticQuery
			query={`${headerQuery}`}
			render={withPreview(renderHeader, headerQuery)}
		/>
	);
};

const Header = props => {
	const isMobile = useMedia({ minWidth: "950px" });
	const doc = props.data.prismic.allTop_navigations.edges.slice(0, 1).pop();
	const [drawerVisible, setDrawerVisible] = useState(false);

	const handleClick = () => {
		setDrawerVisible(!drawerVisible);
	};

	return (
		<header className={classes.headerContainer}>
			<div className={classes.container}>
				<CompanyLogo className={classes.svg} />
				<div className={classes.menuContainer}>
					<ul aria-hidden={!isMobile}>
						{doc.node.links.map((link, index: number) => {
							return (
								<li className={classes.listLink} key={index}>
									<PrismicHref
										link={link.link}
										className={classes.link}
									>
										{RichText.asText(link.link_title)}
									</PrismicHref>
								</li>
							);
						})}
					</ul>
					<button
						style={{ backgroundColor: "#fff" }}
						aria-haspopup="true"
						aria-expanded={drawerVisible}
						aria-controls={`drawer`}
						aria-label={"Menu"}
						className={classes.drawer}
						onClick={handleClick}
						aria-hidden={isMobile}
					>
						<Drawer className={classes.drawerIcon} />
					</button>
				</div>
			</div>
			<AnimateHeight
				duration={250}
				height={drawerVisible ? "auto" : 0} // see props documentation below
			>
				<ul className={classes.mobileList} id="drawer">
					{doc.node.links.map((link, index: number) => {
						return (
							<li className={classes.mobileLinkItem} key={index}>
								<PrismicHref
									link={link.link}
									className={classes.link}
								>
									{RichText.asText(link.link_title)}
								</PrismicHref>
							</li>
						);
					})}
				</ul>
			</AnimateHeight>
		</header>
	);
};

export default HeaderFetch;
