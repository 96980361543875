import clsx from "clsx";
import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { ReactElement, useState } from "react";

import NewsletterIcon from "../../assets/newsletter.svg";
import classes from "./newsletter.module.css";

interface Props {
	c?: any;
}

const NewsletterContainer = (props: Props): ReactElement => {
	const [userInput, setUserInput] = useState("");
	const [userFirst, setUserFirst] = useState("");
	const [userLast, setUserLast] = useState("");
	const [status, setStatus] = useState<"EMPTY" | "INFLIGHT" | "DONE">(
		"EMPTY"
	);

	const handleSubmit = e => {
		e.preventDefault();
		if (userInput && status === "EMPTY") {
			setStatus("INFLIGHT");
			try {
				addToMailchimp(userInput, {
					FNAME: userFirst,
					LNAME: userLast,
				}).then(() => {
					setStatus("DONE");
				});
			} catch (e) {
				setStatus("DONE");
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className={classes.formContainer}>
				<div className={classes.titleContainer}>
					<NewsletterIcon />
					<div className={classes.formTitle}>Stay up to date</div>
				</div>
				<div className={classes.row}>
					<input
						placeholder="Your email address"
						required
						type={"email"}
						className={classes.inputField}
						onChange={e => {
							setUserInput(e.target.value);
						}}
					/>
				</div>
				<div className={classes.row}>
					<input
						placeholder="First Name"
						required
						type={"text"}
						className={classes.inputField}
						onChange={e => {
							setUserFirst(e.target.value);
						}}
					/>
				</div>
				<div className={classes.row}>
					<input
						placeholder="Last Name"
						required
						type={"text"}
						className={classes.inputField}
						onChange={e => {
							setUserLast(e.target.value);
						}}
					/>
				</div>
				<div className={classes.inputContainer}>
					<input
						value="Subscribe"
						disabled={status === "INFLIGHT" || status === "DONE"}
						type="submit"
						className={classes.button}
					/>
					<div
						style={{ position: "absolute", left: "-5000px" }}
						aria-hidden="true"
					>
						<input
							type="text"
							name="b_074a302d8824add3fa3dbcc1c_2292d9660c"
							tabIndex={-1}
							defaultValue=""
						/>
					</div>
				</div>
				{status === "DONE" && (
					<div className={classes.complete}>
						Welcome to our newsletter!
					</div>
				)}
			</div>
		</form>
	);
};

export default NewsletterContainer;
