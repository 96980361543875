import React, { ReactElement, ReactNode } from "react";

import { linkResolver } from "../../utilities/linkResolver";

interface Props {
	ariaLabel?: string;
	children: ReactNode;
	className?: string;
	link: any;
	newTab?: boolean;
}
export const checkUrl = link => {
	if (link._linkType === "Link.document") {
		return linkResolver ? linkResolver(link._meta) : "";
	} else {
		return link.url;
	}
};

const PrismicHref = (props: Props): ReactElement => {
	const attributes = {
		"aria-label": props.ariaLabel,
		href: checkUrl(props.link),
		target: "_self",
		rel: "",
		className: props.className,
	};
	if (props.newTab) {
		attributes.target = "_blank";
		attributes.rel = "noopener noreferrer";
	}

	return <a {...attributes}>{props.children}</a>;
};

export default PrismicHref;
